import { GraphQLHandler, HttpHandler } from 'msw';
import { handlers as ApCounterpartyHandlers } from './ap-counterparty';
import { handlers as ApCurrencyRateHandlers } from './ap-currency-rates';
import { handlers as ApOfficeForeignCurrencySetting } from './ap-office-foreign-currency-setting';
import { handlers as ApPayeeHandlers } from './ap-payee';
import { handlers as ApStamplessContractHandlers } from './ap-stampless-contract';
import { handlers as BusinessDocumentHandlers } from './business-document';
import { handlers as GeneralReportsHandlers } from './general-reports';
import { handlers as GraphQLHandlers } from './graphql/login';
import { handlers as InvoiceHandlers } from './invoice';
import { handlers as InvoiceReportHandlers } from './invoice-report';
import { handlers as MfFileHandlers } from './mfFile';
import { handlers as OpenFeatureHandlers } from './open-feature';
import { handlers as TpmInvoiceHandlers } from './tpm-invoice';

export const mockHandlers: (HttpHandler | GraphQLHandler)[] = [
  ...InvoiceHandlers,
  ...MfFileHandlers,
  ...ApPayeeHandlers,
  ...InvoiceReportHandlers,
  ...BusinessDocumentHandlers,
  ...TpmInvoiceHandlers,
  ...GeneralReportsHandlers,
  ...ApStamplessContractHandlers,
  ...OpenFeatureHandlers,
  ...GraphQLHandlers,
  ...ApCounterpartyHandlers,
  ...ApCurrencyRateHandlers,
  ...ApOfficeForeignCurrencySetting,
];
